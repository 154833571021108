import { useEffect, useState } from "react";
import "./App.css";
import { Routes, Route, Outlet, Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import config from "./config";
import axios from "axios";

const getMobileOS = () => {
  const ua = navigator.userAgent;
  if (/android/i.test(ua)) {
    return "Android";
  } else if (
    /iPad|iPhone|iPod/.test(ua) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  ) {
    return "iOS";
  }
  return "Other";
};
const getDeepLink = (path = "") => {
  const scheme = "works-app";
  const prefix =
    getMobileOS() == "Android" ? `${scheme}://works/` : `${scheme}://`;
  return prefix + path;
};

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={"/images/Logo@2x.png"} className="App-logo" alt="logo" />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/orders/:id/complete" element={<OrderCheck />} />
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </header>
    </div>
  );
}

function OrderCheck() {
  const { id } = useParams();
  const [isChecking, setIsChecking] = useState(true);
  const [data, setData] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    //console.log(id);
    let intervalId = setInterval(() => {
      try {
        axios
          .get(config.apiUrl + "/mobile-app/get-omise-payment/" + id)
          .then((res) => {
            //console.log(res.data);
            let _data = res.data;
            setData(_data);
            if (_data.status) {
              setIsChecking(false);
              clearInterval(intervalId);
              //navigate(getDeepLink());

              setTimeout(()=>{
                window.location = getDeepLink();
              },500)
            }
            if (!_data.status && _data.payment != "pending") {
              setIsChecking(false);
              clearInterval(intervalId);
              //navigate(getDeepLink());
              setTimeout(()=>{
                window.location = getDeepLink();
              },500)
            }
          })
          .catch((err) => {
            //console.log(err);
            setIsChecking(false);
            clearInterval(intervalId);
            //navigate(getDeepLink());
            setTimeout(()=>{
              window.location = getDeepLink();
            },500)
            
          });
      } catch (error) {
        //console.log(error);
        setIsChecking(false);
        clearInterval(intervalId);
        //navigate(getDeepLink())
        setTimeout(()=>{
          window.location = getDeepLink();
        },500)
      }
    }, 500);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  if (isChecking) {
    return (
      <div>
        <br />
        <br />
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h1>Please waiting...</h1>
      </div>
    );
  } else {
    return (
      <div>
        <br />
        <br />
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        {data.status && (
          <>
            <h1>Successful</h1>
            <Link to={getDeepLink()}>Back to Merchant</Link>
          </>
        )}
        {!data.status && (
          <>
            <h1>Not successful</h1>
            <Link to={getDeepLink()}>Back to Merchant</Link>
          </>
        )}
      </div>
    );
  }
}

function Layout() {
  return (
    <div>
      <hr />
      <Outlet />
    </div>
  );
}

function Home() {
  return (
    <div>
      <h1>Home</h1>
      <Link to={getDeepLink()}>Back to Merchant</Link>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h1>Nothing to see here!</h1>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;
